<template lang="pug">
  .sync-list-modal
    TopBar(
      :item-title="$t('sync_list.shop_name')"
      :item-name="shop.name"
      edit-link-name="ShopMatching"
    )
    SyncedItem.m-t-30(
      :synced-item-title="$t('sync_list.synced_shop')"
      :sources-items="shop.sources_shops"
    )
    InventoryGroup(
      v-if="!isNicolasEnabled"
      :inventoryGroupName="shop.inventory_group.name"
    )
</template>

<script>
  import { mapGetters } from "vuex"

  export default {
    components: {
      TopBar: () => import("@/components/SyncList/TopBar"),
      SyncedItem: () => import("@/components/SyncList/SyncedItem"),
      InventoryGroup: () => import("@/components/SyncList/InventoryGroup"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader")
    },

    props: {
      shop: {
        type: Object,
        required: true
      }
    },

    computed: {
      ...mapGetters(["isNicolasEnabled"])
    }
  }
</script>

<style lang="sass" scoped>
  .sync-list-modal
    width: 600px
</style>
